import React from "react"
import SEO from "../components/seo"
import Chapter3 from "src/views/Chapter3"
import OGImage from "../images/seo/teatr_wyobrazni.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Teatr wyobraźni"
        description="Słuchowiska, powieści radiowe, adaptacje dzieł literackich – wszystko, co najlepsze w historii Teatru Polskiego Radia"
        image={OGImage}
      />
      <Chapter3 />
    </>
  )
}

export default IndexPage
