import React, { useRef } from "react"
import { useArticles } from "src/hooks"
import TitleAndParagraph from "src/components/TitleAndParagraph"
import { Iterator } from "src/util"
import NextChapterLink from "src/components/NextChapterLink"
import BodyWrap from "src/components/BodyWrap"
import { AnimatePresence, motion } from "framer-motion"
import H1 from "src/components/H1"
import Gallery from "../../../components/Gallery"
import TitleParagraphVideoAudio from "src/components/TitleParagraphVideoAudio"
import VideoPlayer from "src/components/VideoPlayer"

const Chapter3 = () => {
  const articles = useArticles({ articleCategory: 8966 })
  const iterator = useRef()

  return (
    <AnimatePresence exitBeforeEnter>
      {articles.list?.length > 0 &&
        (iterator.current = new Iterator({ array: articles.list })) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BodyWrap>
              <H1 />
              <TitleAndParagraph article={iterator.current.current()} />
              <TitleAndParagraph article={iterator.current.next()} />
              <Gallery articles={iterator.current.next(15)} />
              <TitleAndParagraph article={iterator.current.next()} />
              <VideoPlayer
                src={iterator.current.current().CustomFields[0]?.Value}
                style={{
                  width: "100vw",
                  marginBottom: "10rem",
                  marginLeft: "-8.8%",
                }}
              />
              <TitleAndParagraph article={iterator.current.next()} />
              <TitleParagraphVideoAudio
                article={iterator.current.next()}
                style={{ marginBottom: "10rem" }}
              />
              <Gallery articles={iterator.current.next(9)} />
              <TitleParagraphVideoAudio
                article={iterator.current.next()}
                style={{ marginBottom: "10rem" }}
              />
              <Gallery articles={iterator.current.next(9)} />
              <TitleAndParagraph article={iterator.current.next()} />
              <Gallery articles={iterator.current.next(9)} />
            </BodyWrap>
            <NextChapterLink
              title="Głosy radiowego teatru"
              href="glosy-radiowego-teatru"
            />
          </motion.div>
        )}
    </AnimatePresence>
  )
}

export default Chapter3
